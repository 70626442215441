import { MDXProvider } from "@mdx-js/react";
import "normalize.css";
import React from "react";

import Heading from "./src/components/Heading/Heading";
import { StyledA } from "./src/components/Link/Link";
import Paragraph from "./src/components/Paragraph/Paragraph";
import GlobalFonts from "./src/styles/fonts";

const components = {
  h1: (props) => <Heading as="h1" {...props} />,
  h2: (props) => <Heading as="h2" {...props} />,
  h3: (props) => <Heading as="h3" {...props} />,
  h4: (props) => <Heading as="h4" {...props} />,
  h5: (props) => <Heading as="h5" {...props} />,
  h6: (props) => <Heading as="h6" {...props} />,
  p: Paragraph,
  a: StyledA,
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>
    <GlobalFonts />
    {element}
  </MDXProvider>
);
