import { Link } from "gatsby";
import styled, { css } from "styled-components";

import Tokens from "../../tokens/build/tokens.js";

interface LinkProps {
  $color?: string;
  $fontWeight?: number | string;
  href?: string;
  target?: string;
  to?: string;
}

const styles = css<LinkProps>`
  position: relative;
  color: ${(props) => props.$color};
  color: inherit;
  text-decoration: none;
  position: relative;
  font-weight: ${(props) => props.$fontWeight || 600};
  margin: 0;

  &::after {
    background-color: ${(props) => props.$color || Tokens.wb.color.black.value};
    content: "";
    position: absolute;
    top: 1.3em;
    height: 1px;
    right: 0;
    width: 0;
    transition: width 0.3s ease-out;
  }

  &:hover::after {
    left: 0;
    width: 100%;
  }
`;

const StyledA: React.FC<React.PropsWithChildren<LinkProps>> = styled.a`
  ${styles}
`;

const StyledLink: React.FC<React.PropsWithChildren<LinkProps>> = styled(Link)`
  ${styles}
`;

export { StyledA, StyledLink };
